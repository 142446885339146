<template>
  <div>
    <div class="d-flex justify-content-center mt-2">
      <b-card
        class="bg-dark-body mb-2  text-center"
      >
        <h4 class="text-primary mb-25 font-size-26">
          Terms and conditions
        </h4>
        <p class="text-header mb-0">
          In order to continue, you have to read and agree with Terms and conditions
        </p>
      </b-card>
    </div>
    <b-card class="mb-2 bg-dark-main border-dark-main therms-card">
      <terms class="terms" />
    </b-card>
    <div class="d-flex">
      <b-button
        class="bg-primary mt-2 mb-4 font-weight-normal ml-auto font-size-18"
        @click="onSignup"
      >
        Agree with terms
      </b-button>
    </div></div>
</template>

<script>
import { BButton, BCard } from 'bootstrap-vue'
import { call, get } from 'vuex-pathify'
import Terms from '@/components/Terms.vue'

export default {
  components: {
    Terms,
    BButton,
    BCard,
  },
  computed: {
    ...get('affiliate', ['isSigned']),
  },
  mounted() {
    if (this.isSigned) {
      this.$router.push('/')
    }
  },
  methods: {
    ...call('affiliate', ['signUpForAffiliate']),
    onSignup() {
      this.signUpForAffiliate().then(() => {
        this.$router.push('/')
      })
    },
  },

}
</script>

<style lang="scss">
  .terms {
    max-height: 60vh;
    overflow-y: scroll;
  }
  .therms-card{
    background-color: rgba($dark-main, 50%);
  }
</style>
